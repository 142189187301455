<template>
    <v-navigation-drawer
        permanent
        :app="app"
        :clipped="clipped"
        :dark="dark"
        :color="color"
        :floating="floating"
        :mini-variant="mini"
        v-if="menus.length > 0"
    >
        <v-list nav dense>
            <div v-for="(group, index) in findGroups('top')" :key="'ct' + index">
                <v-subheader v-if="group">{{ $t(group) }}</v-subheader>
                <v-tooltip
                    v-for="(menu, index) in findMenus('top', group)"
                    :key="index"
                    right
                    dark
                    open-delay="250"
                    color="grey darken-4"
                >
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" :to="menu.to">
                            <v-list-item-icon>
                                <v-icon>{{ menu.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t(menu.label) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <span>{{ $t(menu.label) }}</span>
                </v-tooltip>
            </div>
        </v-list>

        <template v-slot:append>
            <v-list dense>
                <div v-for="(group, index) in findGroups('bottom')" :key="'cb' + index">
                    <v-subheader v-if="group">{{ $t(group) }}</v-subheader>
                    <v-tooltip
                        v-for="(menu, index) in findMenus('bottom', group)"
                        :key="index"
                        right
                        dark
                        open-delay="250"
                        color="grey darken-4"
                    >
                        <template v-slot:activator="{ on }">
                            <v-list-item v-on="on" :to="menu.to">
                                <v-list-item-icon>
                                    <v-icon>{{ menu.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(menu.label) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <span>{{ $t(menu.label) }}</span>
                    </v-tooltip>
                    <v-list-item v-if="allowMini" link @click="mini = !mini">
                        <v-list-item-icon>
                            <v-icon v-if="!mini">mdi-chevron-double-left</v-icon>
                            <v-icon v-if="mini">mdi-chevron-double-right</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('drawer.collapse') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'drawer',
    data: () => ({
        mini: true,
        activeMenu: undefined,
    }),
    props: {
        menus: {
            required: true,
        },
        allowMini: {
            default: true,
        },
        app: {
            default: true,
        },
        clipped: {
            default: true,
        },
        dark: {
            default: true,
        },
        floating: {
            default: false,
        },
        color: {},
    },
    mounted() {
        if (this.allowMini === false) {
            this.mini = false;
        }
    },
    methods: {
        findGroups(position) {
            return [...new Set(this.menus.filter((m) => m.position === position).map((m) => m.group))];
        },
        findMenus(position, group) {
            const menusToDisplay = this.menus
                .filter((m) => m.position === position && m.group === group)
                .map((m) => {
                    if (!m.to) {
                        m.to = { name: this.$route.name, hash: '#' + m.name };
                    } else if (typeof m.to === 'string') {
                        m.to = { name: m.to };
                    }

                    return m;
                });
            return menusToDisplay;
        },
    },
};
</script>
